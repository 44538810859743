<template>
  <div class="guidePage">
    <div class="guide-content">
      <div class="icon-arrow arrow-left" @click="$refs.guide_swipe.swipeTo(0)">
        <van-icon name="arrow-left" />
      </div>
      <div class="swipe-content">
        <van-swipe
          ref="guide_swipe"
          :autoplay="300000"
          indicator-color="white"
          :loop="false"
          :show-indicators="false"
        >
          <van-swipe-item>
            <div class="content-r">
              <div
                :class="['item', item.class]"
                v-for="(item, index) in listData"
                :key="index"
                @click="redirect(item.url)"
              >
                <img :src="item.icon" alt="" />
                <p>{{ item.text }}</p>
              </div>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="content-r">
              <div :class="['item', 'mrqd']" @click="redirect('DailySign')">
                <img src="./img/img-7.png" alt="" />
                <p>每日签到</p>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="icon-arrow arrow-right" @click="$refs.guide_swipe.swipeTo(1)">
        <van-icon name="arrow" />
      </div>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from "vant";

export default {
  name: "guidePage",
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Icon.name]: Icon,
  },
  props: {},
  data() {
    return {
      listData: [
        {
          text: "我要参加活动",
          icon: require("./img/img-1.png"),
          url: "joinActivity",
          class: "wycjhd",
        },
        // {
        //   text: "活动签到",
        //   icon: require("./img/img-2.png"),
        //   url: "myCheckIn",
        //   class: 'hdqd'
        // },
        {
          text: "我的信息",
          icon: require("./img/img-4.png"),
          url: "myInformation",
          class: "wdxx",
        },
        {
          text: "小亭食堂",
          icon: require("./img/img-6.png"),
          url: "ytjEldCanMealsList",
          class: "xtst",
        },
        {
          text: "我的活动",
          icon: require("./img/img-3.png"),
          url: "myActivity",
          class: "wdhd",
        },
        {
          text: "我要兑换",
          icon: require("./img/img-5.png"),
          url: "myCovert",
          class: "wydh",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    redirect(name) {
      if (name === "ytjEldCanMealsList") {
        this.$store.commit("addNoKeepAlive", "serviceSystem"); // 清除食堂页面缓存
      }
      this.$router.push({
        name,
      });
    },
  },
};
</script>

<style scoped lang="less">
.guidePage {
  display: flex;
  justify-content: center;
}
.content-l {
  flex-shrink: 0;
  width: 310px;
  height: 426px;
  background: linear-gradient(#1c77ff 0%, #58bbff 100%);
  border-radius: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  margin-right: 26px;
  box-sizing: border-box;

  img {
    margin-bottom: 57px;
    width: 140px;
    height: 140px;
  }
  p {
    font-family: MicrosoftYaHei, MicrosoftYaHei;
    font-weight: bold;
    font-size: 30px;
    color: #ffffff;
    line-height: 40px;
    font-style: normal;
  }
}
.content-r {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 10px;
  .item {
    width: calc(33.3% - 16px);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    border-radius: 36px;
    box-sizing: border-box;
    padding-right: 20px;
    &:not(:nth-child(3n)) {
      margin-right: 24px;
    }
    &.wycjhd {
      background: linear-gradient(#1c77ff 0%, #58bbff 100%);
      margin-bottom: 24px;
    }
    &.hdqd {
      background: linear-gradient(180deg, #ff9d87 0%, #fc7668 100%);
      margin-bottom: 24px;
    }
    &.wdxx {
      background: linear-gradient(180deg, #29daf9 0%, #23a0e5 100%);
      margin-bottom: 24px;
    }
    &.xtst {
      background: linear-gradient(180deg, #7542ff 0%, #b98cff 100%);
    }
    &.wdhd {
      background: linear-gradient(180deg, #84e9ba 0%, #00cf96 100%);
    }
    &.wydh {
      background: linear-gradient(180deg, #ffc73c 0%, #f99141 100%);
    }
    &.mrqd {
      background: linear-gradient(180deg, #ffe390 0%, #ffb300 100%);
      margin-bottom: 24px;
    }

    img {
      width: 140px;
      height: 140px;
      margin-right: 10px;
    }
    p {
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      font-size: 30px;
      color: #ffffff;
      line-height: 40px;
      font-style: normal;
    }
  }
}

.guide-content {
  box-sizing: border-box;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px;
  .swipe-content {
    box-sizing: border-box;
    flex-shrink: 0;
    width: calc(100% - 180px);
    height: 424px;
    margin: 0px 10px;
  }
}
.icon-arrow {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: linear-gradient(180deg, #3397fe 0%, #3666ef 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
</style>
